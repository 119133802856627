
const menu = {
  data: [
    {
      title: '待审核患者',
      code: 'audit-patient:access',
      isShow: false,
      link: 'auditingPatient',
      route_name: 'auditingPatient',
      icon_class: 'el-icon-s-check',
      menu_id: 1,
      id: 1,
      parent_permission_id: 0
    },
    {
      title: '待处理任务',
      code: 'verify-task:access',
      isShow: false,
      link: 'pendingTask',
      route_name: 'pendingTask',
      icon_class: 'el-icon-s-claim',
      menu_id: 6,
      id: 6,
      parent_permission_id: 0
    },
    {
      title: '患者管理',
      code: 'patient-mgr:access',
      isShow: false,
      link: 'enterPatient',
      route_name: 'enterPatient',
      icon_class: 'el-icon-user-solid',
      menu_id: 3,
      id: 3,
      parent_permission_id: 0
    },
    {
      title: '数据统计',
      code: 'statistics:access',
      isShow: false,
      link: 'statistics',
      route_name: 'statistics',
      icon_class: 'el-icon-s-data',
      menu_id: 5,
      id: 5,
      parent_permission_id: 0
    },
    {
      title: '系统设置',
      code: 'system-settings:access',
      isShow: false,
      link: 'systemSetting',
      route_name: 'systemSetting',
      icon_class: 'el-icon-setting',
      menu_id: 7,
      id: 7,
      parent_permission_id: 0,
      children: [
        {
          title: '校对数据配置',
          code: 'data-verify-settings:access',
          isShow: false,
          link: 'verifyDataConfig',
          route_name: 'verifyDataConfig',
          menu_id: 11,
          id: 11,
          parent_permission_id: 7
        },
        {
          title: '医院管理',
          code: 'hospital-mgr:access',
          isShow: false,
          link: 'HospitalManage',
          route_name: 'HospitalManage',
          menu_id: 13,
          id: 13,
          parent_permission_id: 7
        },
        {
          title: '设备管理',
          code: 'device-mgr:access',
          isShow: false,
          link: 'deviceManage',
          route_name: 'deviceManage',
          menu_id: 14,
          id: 14,
          parent_permission_id: 7
        },
        {
          title: '权限管理',
          code: 'permission-mgr:access',
          isShow: false,
          link: 'PermissionManage',
          route_name: 'PermissionManage',
          menu_id: 12,
          id: 12,
          parent_permission_id: 7
        },
        {
          title: '操作日志',
          code: 'operation-log:access',
          isShow: false,
          link: 'operationLog',
          route_name: 'operationLog',
          menu_id: 10,
          id: 10,
          parent_permission_id: 7
        },
        {
          title: '修改密码',
          code: 'password-change:access',
          isShow: false,
          link: 'changePassword',
          route_name: 'changePassword',
          menu_id: 8,
          id: 8,
          parent_permission_id: 7
        }
      ]
    }
  ]
}

export default menu
