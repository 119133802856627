<template>
  <div class="layout">
    <the-header></the-header>
    <div class="layout-box">
      <the-slide :menu-list="menuList"></the-slide>
        <div class="container">
          <!--      <theTab></theTab>-->
<!--          <Breadcrumb></Breadcrumb>-->
          <div class="main">
            <el-scrollbar ref="scrollTop" style="height: 100%">
            <router-view></router-view>
            </el-scrollbar>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import TheHeader from './TheHeader'
// import Breadcrumb from '@/components/Breadcrumb'
import TheSlide from './TheSlide'
import menu from '@/utils/menu'
// import { map } from 'utility'
export default {
  name: 'Layout',
  components: {
    TheHeader,
    TheSlide
    // Breadcrumb
  },
  data () {
    return {
      menuList: [],
      userMenuList: [],
      permissionDict: []
    }
  },
  watch: {
    $route (to, from) {
      this.$refs.scrollTop.wrap.scrollTop = 0
    }
  },
  async created () {
    const res = menu
    this.permissionDict = res.data
    const authList = JSON.parse(sessionStorage.getItem('auth')).perms
    this.getMenuList(authList)
    this.parentMenuHandle(this.userMenuList, this.permissionDict)
    this.menuList = this.permissionDict
  },
  methods: {
    getMenuList (list) {
      this.userMenuList = []
      list.map(i => {
        if (i.indexOf('access') !== -1) {
          this.userMenuList.push(i)
        }
      })
      if (sessionStorage.getItem('LoginStatus')) {
        sessionStorage.removeItem('LoginStatus')
        window.location.reload()
      }
    },
    parentMenuHandle (userMenuList, permissionDict) {
      permissionDict.map(item => {
        if (userMenuList.indexOf(item.code) !== -1) {
          item.isShow = true
        }
        if (item.children) {
          item.children.map(itemer => {
            if (userMenuList.indexOf(itemer.code) !== -1) {
              itemer.isShow = true
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  $layout-background: #f5f7f9;
  .layout {
    height: 100%;
    background: #fff;
    position: relative;
    /*display: flex;*/
    /*flex-direction: column;*/
    .layout-box {
      display: flex;
      /*flex: 1;*/
      height: calc(100% - 60px);
    }
    .container {
      overflow: hidden;
      flex: 1;
      padding-top: 21.5px;
      .main {
        width: 100%;
        min-width: 980px;
        height: calc(100%);
        background: #fff;
        /*padding: 17px 20px 0;*/
        /*padding-top: 20px;*/
        .el-scrollbar__wrap    {

          overflow-x: hidden!important;

        }
      }
    }
    .el-menu {
      border-right: none;
    }
  }
  .el-menu {
    border-right: none;
  }
</style>
<style lang="scss">
  .main {
    width: 100%;
    min-width: 980px;
    height: calc(100%);
    background: #fff;
    /*padding: 17px 20px 0;*/
    /*padding-top: 20px;*/
    .el-scrollbar__wrap    {
      overflow-x: hidden!important;
    }
  }
</style>
