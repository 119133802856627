<template>
  <div id="menu">
<!--    <el-radio-group v-model="isCollapse" style="position: fixed;top: 0px;  margin-bottom: 20px;">-->
<!--      <el-radio-button :label="false">展开</el-radio-button>-->
<!--      <el-radio-button :label="true">收起</el-radio-button>-->
<!--    </el-radio-group>-->
    <el-scrollbar>
      <el-menu :default-active="$route.path" background-color="#1D3043" text-color="#fff" :collapse-transition="false" active-text-color="#fff" router class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="showCollapse">
        <template v-for="menu in menuList">
          <el-submenu :key="menu.id" v-if="menu.children && menu.isShow" :index="'' + menu.id">
            <template slot="title">
              <i :class="menu.icon_class"></i>
              <span slot="title">{{menu.title}}</span>
            </template>
            <div v-for="subMenu in menu.children" :key="subMenu.id">
            <el-menu-item v-if="subMenu.isShow" :index="'/' + menu.link + '/' + subMenu.link">{{subMenu.title}}</el-menu-item>
            </div>
          </el-submenu>
          <el-menu-item v-else-if="menu.isShow" :key="menu.link" :index="'/' + menu.link">
            <i :class="menu.icon_class"></i>
            <span slot="title">{{menu.title}}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  props: {
    menuList: Array
  },
  data () {
    return {
      isCollapse: false
    }
  },
  created () {
  },
  computed: {
    showCollapse () {
      if (
        this.$route.path === '/completeInfo' ||
        this.$route.path === '/patientFiles' ||
        this.$route.path === '/proofread' ||
        this.$route.path === '/statistics') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
      // if (key === '1' && this.$route.path !== '/pendingTask') {
      //   this.$router.push({
      //     path: '/pendingTask'
      //   })
      // }
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
      // if (key === '1' && this.$route.path !== '/pendingTask') {
      //   this.$router.push({
      //     path: '/pendingTask'
      //   })
      // }
    }
  }
}
</script>

<style scoped lang="scss">
  #menu {
    overflow: hidden;
    height: 100%;
    .aside-box {
      /*height: 100%;*/
    }
    .top-logo {
      height: 60px;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 40px;
      }
      .logo-title {
        color: #ffffff;
        font-size: 14px;
        font-weight: bolder;
        margin-left: 10px;
      }
    }
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden !important;
    }
    ::v-deep .el-scrollbar__view {
      height: 100%;
    }
    .el-scrollbar {
      width: 100%;
      height: 100%;
    }
    ::v-deep .el-menu-vertical-demo {
      height: 100%;
      .el-submenu__title {
        text-align: left;
      }
      .el-menu-item {
        text-align: left;
        // font-size: 18px;
      }
      &:not(.el-menu--collapse) {
        width: 210px;
        min-height: 400px;
      }
    }
    .el-submenu__icon-arrow {
      display: none;
    }
  }
</style>
